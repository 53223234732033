import md5 from 'js-md5'
export var SearchList = [
  { labe: '采购订单', code: 'order', type: 'input', placeholder: '编码/名称' },
  { labe: '付款单号', code: 'payCode', type: 'input', placeholder: '编码/名称' },
  { labe: '开票方', code: 'drawer', type: 'input', placeholder: '编码/名称' },
  { labe: '网上发票号', code: 'onlineInvoiceNumber', type: 'input', placeholder: '采购员编码或名称' },
  {
    labe: '创建日期',
    code: 'createDate',
    type: 'daterange'
  },
  {
    labe: '付款方式',
    code: 'payWay',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '银行存款', value: '02' },
      { label: '银承汇票', value: '01' },
      { label: '贷款支付', value: '03' }
    ]
  },
  {
    labe: '申请类型',
    code: 'type',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '应付', value: '1' },
      { label: '预付', value: '2' }
    ]
  },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '审批中', value: '1' },
      { label: '驳回', value: '2' },
      { label: '审批通过', value: '3' },
      { label: '出纳已处理', value: '4' },
      { label: '出纳已驳回', value: '5' },
      { label: '付款未过账', value: '6' },
      { label: '付款已完成', value: '7' },
      { label: '付款已冲销', value: '8' }
    ]
  }
]
export var SearchData = {
  payCode: '',
  drawerName: '',
  // createDate: '',
  startTime: '',
  endTime: '',
  payWay: '',
  type: '',
  status: ''
}

export var menudata = [{
  label: '+创建付款申请',
  action: 'Createapaymentreq',
  id: 1
},
{
  label: '+创建预付款申请',
  action: 'CreateaplanPayreq',
  id: 2
}
]

export var tableField = [
  { label: '付款单号 ', code: 'payCode', type: 'input', width: '140' },
  {
    label: '申请类型',
    code: 'type',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.type === 1 ? '应付' : '预付'
    }
  },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(0, 6)
      const label = data.status === 1 ? '审批中' : data.status === 2 ? '驳回' : data.status === 3 ? '审批通过' : data.status === 4 ? '出纳已处理'
        : data.status === 5 ? '出纳已驳回' : data.status === 6 ? '付款未过账' : data.status === 7 ? '付款已完成' : data.status === 8 ? '付款已冲销'
          : data.status
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  { label: '申请总额', code: 'totalMoney', type: 'amount', align: 'right', width: '' },
  { label: '币种', code: 'currency', type: 'input', width: '' },
  {
    label: '付款方式',
    code: 'payWay',
    type: 'function',
    width: '',
    handle (index, data) {
      return data.payWay === '01' ? '银承汇票' : data.payWay === '02' ? '银行存款' : data.payWay === '03' ? '贷款支付' : data.payWay
    }
  },
  {
    label: '开票方',
    code: 'drawerName',
    type: 'function',
    width: '120px',
    handle (index, row) {
      return `${row.drawerName}-${row.drawerCode}`
    }
  },
  { label: '银行账号', code: 'bankAccount', type: 'input', width: '' },
  { label: '付款账号', code: 'payAccount', type: 'input', width: '' },
  /*  { label: '已付款金额', code: 'payMoney', type: 'amount', align: 'right', width: '120' }, */
  { label: '备注', code: 'bz', type: 'input', width: '' },
  {
    label: '同步状态',
    code: 'syncStatus',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.syncStatus === 'E' ? '失败' : data.syncStatus === 'S' ? '成功' : data.syncStatus
    }
  },
  // { label: '操作记录', code: 'operatingRecord', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }/* ,
      {
        actionLabel: '编辑',
        operationButton: 'edit'
      } */
    ]
  }
]

export var tableData = []
