<template>
<!-- 付款单查询 -->
    <div class="list">
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
      <div class="contractMenu">
        <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
      </div>
      <global-table  :tableField="tableField"  :tableData="tableData" @tableAction="tableAction"  :paginationData="paginationData" @pageChange="pageChange"></global-table>
    </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import ListMenu from '@/components/actionList/ListMenu.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { SearchList, SearchData, tableField, tableData } from './js/paymentFind'
export default {
  components: { ListSearch, GlobalTable, ListMenu },
  name: 'PaymentFind',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      searchdata: SearchData,
      menudata: this.$store.state.ViewMenuData.menuData,
      paginationData: {},
      pageNum: 1,
      pageSize: pageSize,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    const obj = {
      pageSize: this.pageSize,
      pageNum: this.pageNum
    }
    request('/api/reconciliation/pay/paymentOrderList', 'get', obj).then((res) => {
      if (res.code === '200') {
        this.tableData = res.data.records
        this.paginationData = { pageSize: this.pageSize, total: res.data.total }
      }
    })
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  methods: {
    buttonClick (data) {
      if (data.alias === 'paymentfindmenu') {
        this.$router.push({ params: { viewName: 'CreatePaymentreq' } })
      } else if (data.alias === 'paymentfindplanmenu') {
        this.$router.push({ params: { viewName: 'CreateaplanPayreq' } })
      }
      console.log(data)
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        if (data.type === 1) {
          // 付款申请
          this.$router.push({ params: { viewName: 'PaymentFindDetail' }, query: { id: data.id } })
        } else {
          //  预付款申请
          this.$router.push({ params: { viewName: 'AdvancePaymentFindDetail' }, query: { id: data.id } })
        }
      } else if (clickBtn.operationButton === 'edit') {
        //  付款申请
        if (data.type === 1) {
          // 付款申请
          this.$router.push({ params: { viewName: 'PaymentFindDetail' }, query: { id: data.id } })
        } else {
          //  预付款申请
          this.$router.push({ params: { viewName: 'AdvancePaymentFindDetail' }, query: { id: data.id } })
        }
        // this.$router.push({ params: { viewName: 'PaymentFindDetail' }, query: { id: data.id } })
      } // // type 1 应付  type 2预付
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.startTime = this.dateFormate(data.createDate[0])
        this.searchdata.endTime = this.dateFormate(data.createDate[1])
      }
      request('/api/reconciliation/pay/paymentOrderList', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/reconciliation/pay/paymentOrderList', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.contractMenu{
 position: relative;
 .derivebtn{
  position: absolute;
  left: 170px;
  top: 8px;
  font-size: 16px;
 }
}
</style>
